<template>
  <div>
    <div v-if="userProfile.id">
      <b-card>
        <validation-observer
          ref="securityTab"
        >
          <b-form>
            <b-row>
              <b-col
                sm="12"
                xl="6"
                class="col-xxl-4"
              >
                <b-form-group
                  :label="$t('old_Password')"
                  label-for="account-old-password"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('old_Password')"
                    vid="old_password"
                    rules="required"
                  >
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        id="account-old-password"
                        v-model="form.old_password"
                        name="old-password"
                        :type="passwordFieldTypeOld"
                        :placeholder="$t('old_Password')"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIconOld"
                          class="cursor-pointer"
                          @click="togglePasswordOld"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                xl="6"
                class="col-xxl-4"
              >
                <b-form-group
                  label-for="account-new-password"
                  :label="$t('new_Password')"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('new_Password')"
                    vid="new_password"
                    rules="required"
                  >
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        id="account-new-password"
                        v-model="form.password"
                        :type="passwordFieldTypeNew"
                        name="new-password"
                        :placeholder="$t('new_Password')"
                        minlength="8"
                        maxlength="16"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIconNew"
                          class="cursor-pointer"
                          @click="togglePasswordNew"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                xl="6"
                class="col-xxl-4"
              >
                <b-form-group
                  label-for="account-retype-new-password"
                  :label="$t('retype_new_password')"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('retype_new_password')"
                    vid="retype_password"
                    rules="required|confirmed:new_password"
                  >
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        id="account-retype-new-password"
                        v-model="form.password_confirmation"
                        :type="passwordFieldTypeRetype"
                        name="retype-password"
                        :placeholder="$t('retype_new_password')"
                        minlength="8"
                        maxlength="16"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIconRetype"
                          class="cursor-pointer"
                          @click="togglePasswordRetype"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-button
                  v-if="!pendingChangePassword"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mt-1 mr-1"
                  :disabled="userProfile.isActive == 0"
                  @click="changePassword"
                >
                  {{ $t('save_changes') }}
                </b-button>
                <b-button
                  v-if="pendingChangePassword"
                  variant="primary"
                  class="mt-1 mr-1"
                  disabled
                >
                  <b-spinner
                    small
                    type="grow"
                  />
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- <b-card>
      <h4 class="card-title">
        {{ $t('setup_deposit_title') }}
      </h4>
      <button
        v-if="!pendingSendPinEmail"
        type="button"
        class="btn btn-outline-primary"
        style="position: relative;"
        :disabled="userProfile.isActive == 0"
        @click="sendPinEmail"
      >
        {{ $t('send_pin_email') }}
      </button>
      <b-button
        v-if="pendingSendPinEmail"
        variant="outline-primary"
        class="btn btn-outline-primary"
        style="position: relative;"
        disabled
      >
        <b-spinner
          small
          type="grow"
        />
      </b-button>
    </b-card> -->
      <b-card>
        <validation-observer
          ref="twoFactor"
        >
          <b-form>
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <b-form-checkbox
                    id="setup"
                    v-model="userProfile.twoFactorAuth.is_active"
                    name="checkbox-1"
                  >
                    {{ $t('Setup') }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group
                  label-for="account-new-password"
                  :label="$t('Setup_two_factor_login_authentication')"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('type')"
                    vid="type"
                    rules="required"
                  >
                    <v-select
                      id="type"
                      v-model="twoFactorType"
                      :disabled="!userProfile.twoFactorAuth.is_active"
                      :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                      :options="options"
                      label="label"
                      name="type"
                      :placeholder="$t('save_changes')"
                      :clearable="false"
                    />
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-button
                  v-if="!pendingTwoFactor"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mt-1 mr-1"
                  @click="saveFactor"
                >
                  {{ $t('save_changes') }}
                </b-button>
                <b-button
                  v-if="pendingTwoFactor"
                  variant="primary"
                  class="mt-1 mr-1"
                  disabled
                >
                  <b-spinner
                    small
                    type="grow"
                  />
                </b-button>
              </b-col>
            </b-row>
          </b-form>

        </validation-observer>
      </b-card>
      <activeSessions />
      <!-- modals -->
      <b-modal
        v-model="confirmFormModal"
        :title="$t('verifyMobile')"
        :hide-footer="true"
        centered
        no-close-on-backdrop
        @hide="formStep = 1"
      >
        <!-- <b-card> -->
        <validation-observer
          v-if="formStep === 1"
          ref="verifyMobile"
        >
          <b-form>
            <b-row>
              <b-col
                cols="12"
              >
                <b-form-group
                  label-for="mobile-country-code"
                  :label="$t('mobile_country_code')"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('mobile_country_code')"
                    vid="mobile_country_code"
                    rules="required"
                  >
                    <v-select
                      id="mobile-country-code"
                      v-model="mobile"
                      :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                      label="precode"
                      :close="false"
                      :filter-by="codeSearch"
                      :options="formsListData.pre_country_code"
                      :placeholder="$t('mobile_country_code')"
                      autocomplete="nope"
                    >
                      <!-- <template
                      slot="option"
                      slot-scope="option"
                    >
                      {{ '+'+ option.precode }}
                    </template>
                    <template
                      slot="selected-option"
                      slot-scope="option"
                    >
                      {{ '+'+ option.precode }}
                    </template> -->
                      <template
                        slot="option"
                        slot-scope="option"
                      >
                        <img
                          :src="option.image"
                          class="language-image"
                        >
                        <span class="select">{{ option.precode }}</span>
                        <span>{{ option.name }}</span>

                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
              >
                <b-form-group
                  :label="$t('mobile_number')"
                  label-for="MobileNumber"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('mobile_number')"
                    vid="mobile_number"
                    rules="required|noZeroStater"
                  >
                    <b-form-input
                      v-model="userProfile.mobile"
                      name="mobile_number"
                      :placeholder="$t('mobile_number')"
                      minlength="3"
                      maxlength="15"
                      @keypress="onlyNumbers($event)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                class="my-1"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="primary"
                  :disabled="profileInfo.is_active == 0"
                  @click="saveMobile"
                >
                  <span v-if="!pendingVerifyMobile">{{ $t('save_changes') }}</span>
                  <span v-else>
                    <b-spinner
                      small
                      type="grow"
                    />
                  </span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
        <!-- </b-card> -->
        <b-row
          v-if="formStep === 2"
        >
          <b-col cols="12">
            {{ $t('otp-description',{ mobile:mobile.name }) }}
          </b-col>
          <b-col
            cols="12"
            class="my-1"
          >
            <flip-countdown
              :label="{ hours: `${this.$t('hour')}`,
                        minutes: `${this.$t('minute')}`,
                        seconds: `${this.$t('second')}` }"
              :style="{direction:'ltr'}"
              :deadline="deadline"
              :show-days="false"
              :show-hours="false"
              @timeElapsed="timeElapsedHandler"
            />
          </b-col>
          <b-col cols="12">
            <validation-observer
              ref="verifyForm"
            >
              <form
                @submit.stop.prevent="handleOTP"
              >
                <b-form-group
                  invalid-feedback="Verification code is required"
                  label-for="verification_code"
                  :label="$t('verification_code')"
                >
                  <div id="divOuter">
                    <div id="divInner">
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('verification_code')"
                        vid="verification-code"
                        rules="required"
                      >
                        <b-form-input
                          id="partitioned"
                          v-model="verifyCode"
                          maxlength="6"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                          on-key-press="if(this.value.length==4) return false;"
                          required
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </div>
                  </div>
                </b-form-group>
                <b-row align-h="between">
                  <b-col cols="auto">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="success"
                      :disabled="verifyCode.length < 4"
                      @click="handleOTP"
                    >
                      <span v-if="!pendingOTPMobile">{{ $t('save') }}</span>
                      <span v-else>
                        <b-spinner
                          small
                          type="grow"
                        />
                      </span>
                    </b-button>
                  </b-col>
                  <b-col cols="auto">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="primary"
                      :disabled="!reSendBtn"
                      @click="resendOTP"
                    >
                      <span v-if="!pendingReSendOtp">{{ $t('re-send') }}</span>
                      <span v-else>
                        <b-spinner
                          small
                          type="grow"
                        />
                      </span>
                    </b-button>
                  </b-col>
                </b-row>
              </form>
            </validation-observer>
          </b-col>
        </b-row>
      </b-modal>
    </div>
  </div>
</template>
<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard,
  BInputGroup, BInputGroupAppend, BFormCheckbox, BSpinner, BModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import FlipCountdown from 'vue2-flip-countdown'
import { required } from '@validations'
import { alphanumeric } from '@/mixins/alphanumeric'
import ProfileApis from '@/services/apis/profile'
import FormApis from '@/services/apis/form'
import axiosIns from '@/libs/axios'
import activeSessions from './activeSessions.vue'

const formData = new FormApis()
const userProfile = new ProfileApis()
export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BSpinner,
    vSelect,
    BModal,
    FlipCountdown,
    activeSessions,
  },
  directives: {
    Ripple,
  },
  mixins: [alphanumeric],
  data() {
    return {
      form: {
        old_password: '',
        password: '',
        password_confirmation: '',
      },
      precode: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      twoFactorType: '',
      options: [
        {
          label: this.$t('email'),
          key: 'email',
        },
        {
          label: this.$t('sms'),
          key: 'sms',
        },
      ],
      status: '',
      pendingChangePassword: false,
      pendingSendPinEmail: false,
      pendingTwoFactor: false,
      formsListData: {
        pre_country_code: [],
      },
      userProfile: {
        profile: {},
        twoFactorAuth: {
          is_active: false,
          type: '',
        },
      },
      mobile: {
        name: '',
        precode: '',
      },
      confirmFormModal: false,
      formStep: 1,
      pendingVerifyMobile: false,
      pendingReSendOtp: false,
      pendingOTPMobile: false,
      deadline: '',
      verifyCode: '',
      reSendBtn: false,
      required,
    }
  },
  computed: {
    profileInfo() {
      return this.$store.state.profile.profileInfo
    },
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    profileInfo() {
      if (this.profileInfo && this.profileInfo.twoFactorAuth) {
        this.mobile = {
          name: this.profileInfo.mobile,
          precode: this.profileInfo.mobile_country_code,
        }
        // eslint-disable-next-line eqeqeq
        if (this.profileInfo.twoFactorAuth.is_active == 1) {
          this.twoFactorType = {
            label: this.$t(`${this.profileInfo.twoFactorAuth.type}`),
            key: this.profileInfo.twoFactorAuth.type,
          }
        }
        this.userProfile = {
          ...this.profileInfo,
          twoFactorAuth: {
            ...this.profileInfo.twoFactorAuth,
            is_active: this.profileInfo?.twoFactorAuth?.is_active === 1,
          },
        }
      }
    },
    // eslint-disable-next-line func-names
    'userProfile.twoFactorAuth.is_active': function (val) {
      if (!val) {
        this.twoFactorType = {
          label: '',
          key: '',
        }
      }
    },
  },
  mounted() {
    formData.countryLists().then(res => {
      const result = res.data.results
      this.formsListData.pre_country_code = result.map(item => ({
        // eslint-disable-next-line global-require, import/no-unresolved, import/no-dynamic-require
        precode: item.precode, id: item.id, name: item.name, image: require(`@/assets/images/flags/${item.abbreviation}.png`),
      }))
    })

    if (this.profileInfo && this.profileInfo.twoFactorAuth) {
      this.mobile = {
        name: this.profileInfo.mobile,
        precode: this.profileInfo.mobile_country_code,
      }
      // eslint-disable-next-line eqeqeq
      if (this.profileInfo.twoFactorAuth.is_active == 1) {
        this.twoFactorType = {
          label: this.$t(`${this.profileInfo.twoFactorAuth.type}`),
          key: this.profileInfo.twoFactorAuth.type,
        }
      }
      this.userProfile = {
        ...this.profileInfo,
        twoFactorAuth: {
          ...this.profileInfo.twoFactorAuth,
          is_active: this.profileInfo?.twoFactorAuth?.is_active === 1,
        },
      }
    }
  },
  methods: {
    codeSearch(option, label, search) {
      const temp = search.toLowerCase()
      return option.name.toLowerCase().indexOf(temp) > -1
          || option.precode.toString().toLowerCase().indexOf(temp) > -1
      // return options.filter(element => element.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 || element.precode.indexOf(search) !== -1)
    },
    async changePassword() {
      const success = await this.$refs.securityTab.validate()
      if (success) {
        this.pendingChangePassword = true
        userProfile.storeChangePassword(this.form).then(res => {
          this.$swal({
            title: res.data.message,
            icon: 'success',
            timer: 2500,
            showConfirmButton: false,
          })
        }).finally(() => {
          this.pendingChangePassword = false
        })
      }
    },
    async saveFactor() {
      this.$refs.twoFactor.validate().then(async success => {
        if (success) {
          this.pendingTwoFactor = true
          const active = this.userProfile.twoFactorAuth.is_active === true ? 1 : 0
          const data = {
            is_active: (active).toString(),
            type: this.twoFactorType.key,
          }
          await userProfile.storeFactor(data).then(res => {
            if (res.data.results.type === 'sms') {
              this.confirmFormModal = true
            }
            if (res.data.results.type === 'email' || res.data.results.is_active === '0') {
              this.$swal({
                title: res.data.message,
                icon: 'success',
                timer: 2500,
                showConfirmButton: false,
              })
            }
          }).finally(() => {
            this.pendingTwoFactor = false
          })
        }
      })
    },
    async sendPinEmail() {
      this.pendingSendPinEmail = true
      userProfile.sendEmail().then(({ data }) => {
        this.$swal({
          title: data.message,
          icon: 'success',
          timer: 2500,
          showConfirmButton: false,
        })
      }).finally(() => {
        this.pendingSendPinEmail = false
      })
    },
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },

    //
    async saveMobile() {
      this.pendingVerifyMobile = true
      const data = {
        mobile: this.userProfile.mobile,
        mobile_country_code: this.mobile.precode,
      }
      const response = await userProfile.sendSMS(data).finally(() => {
        this.pendingVerifyMobile = false
      })
      // this.showReaction(response)
      this.formStep = 2
      if (response.status === 200) {
        this.myModal = true
      }
      this.deadline = this.getExpireTime()
    },

    async handleOTP() {
      this.pendingOTPMobile = true
      const success = await this.$refs.verifyForm.validate()
      if (success) {
        await axiosIns.post('verify/code/mobile', { code: this.verifyCode })
          .then(res => {
            this.confirmFormModal = false
            this.$swal({
              title: res.data.message,
              icon: 'success',
              timer: 2500,
              showConfirmButton: false,
            })
            this.confirmFormModal = false
          }).catch(error => {
            if (error.response.status === 422) {
              this.toast('Verification code is wrong', 'danger')
            }
          })
          .finally(() => {
            this.pendingOTPMobile = false
          })
      }
    },

    async resendOTP() {
      this.pendingReSendOtp = true
      const data = {
        mobile: this.userProfile.mobile,
        mobile_country_code: this.mobile.precode,
      }
      const response = await userProfile.sendSMS(data).finally(() => {
        this.pendingReSendOtp = false
      })
      if (response) {
        this.deadline = this.getExpireTime()
        this.reSendBtn = false
        await this.$refs.verifyForm.reset()
      }
    },

    getExpireTime() {
      const expireTime = 2.0
      const currentDate = new Date()
      const msSinceEpoch = currentDate.getTime()
      const seventeenHoursLater = new Date(msSinceEpoch + expireTime * 60 * 1000)
      const currentDayOfMonth = seventeenHoursLater.getDate()
      const currentMonth = seventeenHoursLater.getMonth()
      const currentYear = seventeenHoursLater.getFullYear()
      const hours = seventeenHoursLater.getHours()
      const minutes = seventeenHoursLater.getMinutes()
      const seconds = seventeenHoursLater.getSeconds()
      const dateString = `${currentYear}-${currentMonth + 1}-${currentDayOfMonth} ${hours}:${minutes}:${seconds}`
      return dateString
    },

    timeElapsedHandler() {
      this.reSendBtn = true
    },
  },
}
</script>
